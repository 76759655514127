export const VALID_GUESSES = [
  'abbr',
  'abed',
  'abet',
  'able',
  'ably',
  'abut',
  'acct',
  'aced',
  'aces',
  'ache',
  'achy',
  'acid',
  'acme',
  'acne',
  'acre',
  'acts',
  'adds',
  'advt',
  'adze',
  'aeon',
  'aery',
  'afar',
  'agar',
  'aged',
  'ages',
  'agog',
  'ague',
  'ahem',
  'ahoy',
  'aide',
  'aids',
  'ails',
  'aims',
  'airs',
  'airy',
  'ajar',
  'akin',
  'alas',
  'albs',
  'ales',
  'alga',
  'alit',
  'ally',
  'alms',
  'aloe',
  'also',
  'alto',
  'alts',
  'alum',
  'amen',
  'amid',
  'amir',
  'ammo',
  'amok',
  'amps',
  'anal',
  'anew',
  'ankh',
  'anon',
  'ante',
  'anti',
  'ants',
  'anus',
  'aped',
  'apes',
  'apex',
  'apps',
  'apse',
  'aqua',
  'arch',
  'arcs',
  'area',
  'ares',
  'aria',
  'arid',
  'arks',
  'arms',
  'army',
  'arts',
  'arty',
  'ashy',
  'asks',
  'asps',
  'assn',
  'asst',
  'atom',
  'atop',
  'atty',
  'auks',
  'aunt',
  'aura',
  'auto',
  'avdp',
  'aver',
  'avid',
  'avow',
  'away',
  'awed',
  'awes',
  'awls',
  'awol',
  'awry',
  'axed',
  'axes',
  'axis',
  'axle',
  'axon',
  'ayes',
  'baas',
  'babe',
  'baby',
  'back',
  'bade',
  'bags',
  'bail',
  'bait',
  'bake',
  'bald',
  'bale',
  'balk',
  'ball',
  'balm',
  'band',
  'bane',
  'bang',
  'bani',
  'bank',
  'bans',
  'barb',
  'bard',
  'bare',
  'barf',
  'bark',
  'barn',
  'bars',
  'base',
  'bash',
  'bask',
  'bass',
  'bast',
  'bate',
  'bath',
  'bats',
  'baud',
  'bawl',
  'bays',
  'bead',
  'beak',
  'beam',
  'bean',
  'bear',
  'beat',
  'beau',
  'beck',
  'beds',
  'beef',
  'been',
  'beep',
  'beer',
  'bees',
  'beet',
  'begs',
  'bell',
  'belt',
  'bend',
  'bent',
  'berg',
  'berm',
  'best',
  'beta',
  'bets',
  'bevy',
  'bias',
  'bibs',
  'bide',
  'bids',
  'bier',
  'biff',
  'bike',
  'bile',
  'bilk',
  'bill',
  'bind',
  'bins',
  'bird',
  'bite',
  'bits',
  'blab',
  'blah',
  'blat',
  'bldg',
  'bled',
  'blew',
  'blip',
  'blob',
  'bloc',
  'blog',
  'blot',
  'blow',
  'blue',
  'blur',
  'blvd',
  'boar',
  'boas',
  'boat',
  'bobs',
  'bode',
  'body',
  'boga',
  'bogs',
  'bogy',
  'boil',
  'bola',
  'bold',
  'bole',
  'boll',
  'bolt',
  'bomb',
  'bond',
  'bone',
  'bong',
  'bony',
  'boob',
  'book',
  'boom',
  'boon',
  'boor',
  'boos',
  'boot',
  'bops',
  'bore',
  'born',
  'bosh',
  'boss',
  'both',
  'bout',
  'bowl',
  'bows',
  'boys',
  'bozo',
  'brad',
  'brag',
  'bran',
  'bras',
  'brat',
  'bray',
  'bred',
  'brew',
  'brig',
  'brim',
  'brow',
  'buck',
  'buds',
  'buff',
  'bugs',
  'bulb',
  'bulk',
  'bull',
  'bump',
  'bums',
  'bung',
  'bunk',
  'buns',
  'bunt',
  'buoy',
  'burg',
  'burn',
  'burp',
  'burr',
  'burs',
  'bury',
  'bush',
  'buss',
  'bust',
  'busy',
  'buts',
  'butt',
  'buys',
  'buzz',
  'byes',
  'byte',
  'cabs',
  'cads',
  'café',
  'cage',
  'cagy',
  'cake',
  'calf',
  'calk',
  'call',
  'calm',
  'came',
  'camp',
  'cams',
  'cane',
  'cans',
  'cant',
  'cape',
  'caps',
  'card',
  'care',
  'carp',
  'cars',
  'cart',
  'case',
  'cash',
  'cask',
  'cast',
  'cats',
  'cave',
  'caws',
  'cede',
  'cell',
  'cent',
  'chad',
  'chap',
  'char',
  'chat',
  'chef',
  'chem',
  'chew',
  'chic',
  'chid',
  'chin',
  'chip',
  'chit',
  'chop',
  'chow',
  'chug',
  'chum',
  'cite',
  'city',
  'clad',
  'clam',
  'clan',
  'clap',
  'claw',
  'clay',
  'clef',
  'clew',
  'clii',
  'clip',
  'clit',
  'clix',
  'clod',
  'clog',
  'clop',
  'clot',
  'cloy',
  'club',
  'clue',
  'clvi',
  'clxi',
  'coal',
  'coat',
  'coax',
  'cobs',
  'cock',
  'coda',
  'code',
  'cods',
  'coed',
  'cogs',
  'coif',
  'coil',
  'coin',
  'coke',
  'cola',
  'cold',
  'cols',
  'colt',
  'coma',
  'comb',
  'come',
  'comm',
  'cone',
  'conj',
  'conk',
  'cons',
  'cont',
  'cook',
  'cool',
  'coon',
  'coop',
  'coos',
  'coot',
  'cope',
  'cops',
  'copy',
  'cord',
  'core',
  'cork',
  'corm',
  'corn',
  'corp',
  'cost',
  'cosy',
  'cote',
  'cots',
  'coup',
  'cove',
  'cowl',
  'cows',
  'cozy',
  'crab',
  'crag',
  'cram',
  'crap',
  'craw',
  'cray',
  'crew',
  'crib',
  'crop',
  'crow',
  'crud',
  'crux',
  'cube',
  'cubs',
  'cuds',
  'cued',
  'cues',
  'cuff',
  'cull',
  'cult',
  'cums',
  'cunt',
  'cups',
  'curb',
  'curd',
  'cure',
  'curl',
  'curs',
  'curt',
  'cusp',
  'cuss',
  'cute',
  'cuts',
  'cyst',
  'czar',
  'dabs',
  'dado',
  'dads',
  'daft',
  'dais',
  'dale',
  'dame',
  'damn',
  'damp',
  'dams',
  'dank',
  'dare',
  'dark',
  'darn',
  'dart',
  'dash',
  'data',
  'date',
  'daub',
  'dawn',
  'days',
  'daze',
  'dded',
  'dead',
  'deaf',
  'deal',
  'dean',
  'dear',
  'debs',
  'debt',
  'deck',
  'deed',
  'deem',
  'deep',
  'deer',
  'deft',
  'defy',
  'deli',
  'dell',
  'demo',
  'dens',
  'dent',
  'deny',
  'dept',
  'desk',
  'dewy',
  'dial',
  'dice',
  'dick',
  'died',
  'dies',
  'diet',
  'diff',
  'digs',
  'dike',
  'dill',
  'dime',
  'dims',
  'dine',
  'ding',
  'dink',
  'dins',
  'dint',
  'dips',
  'dire',
  'dirk',
  'dirt',
  'disc',
  'dish',
  'disk',
  'diss',
  'diva',
  'dive',
  'dock',
  'docs',
  'dodo',
  'doer',
  'does',
  'doff',
  'dogs',
  'dole',
  'doll',
  'dolt',
  'dome',
  'done',
  'dons',
  'doom',
  'door',
  'dope',
  'dopy',
  'dork',
  'dorm',
  'dory',
  'dose',
  'dote',
  'doth',
  'dots',
  'dour',
  'dove',
  'down',
  'doze',
  'drab',
  'drag',
  'dram',
  'draw',
  'dray',
  'drew',
  'drip',
  'drop',
  'drub',
  'drug',
  'drum',
  'drys',
  'dual',
  'dubs',
  'duck',
  'duct',
  'dude',
  'duds',
  'duel',
  'dues',
  'duet',
  'duff',
  'duke',
  'dull',
  'duly',
  'dumb',
  'dump',
  'dune',
  'dung',
  'dunk',
  'duns',
  'duos',
  'dupe',
  'dusk',
  'dust',
  'duty',
  'dyed',
  'dyer',
  'dyes',
  'dyke',
  'each',
  'earl',
  'earn',
  'ears',
  'ease',
  'east',
  'easy',
  'eats',
  'eave',
  'ebbs',
  'echo',
  'ecru',
  'eddy',
  'edge',
  'edgy',
  'edit',
  'eels',
  'eery',
  'eggs',
  'egis',
  'egos',
  'eked',
  'ekes',
  'elks',
  'ells',
  'elms',
  'else',
  'emir',
  'emit',
  'emos',
  'emus',
  'ends',
  'envy',
  'eons',
  'epic',
  'eras',
  'ergo',
  'ergs',
  'errs',
  'espy',
  'etch',
  'euro',
  'even',
  'ever',
  'eves',
  'evil',
  'ewer',
  'ewes',
  'exam',
  'excl',
  'exec',
  'exes',
  'exit',
  'expo',
  'eyed',
  'eyes',
  'face',
  'fact',
  'fade',
  'fads',
  'fags',
  'fail',
  'fain',
  'fair',
  'fake',
  'fall',
  'fame',
  'fang',
  'fans',
  'fare',
  'farm',
  'fart',
  'fast',
  'fate',
  'fats',
  'faun',
  'fawn',
  'faze',
  'fear',
  'feat',
  'feds',
  'feed',
  'feel',
  'fees',
  'feet',
  'fell',
  'felt',
  'fend',
  'fens',
  'fern',
  'fest',
  'feta',
  'feud',
  'fiat',
  'fibs',
  'fief',
  'fife',
  'figs',
  'file',
  'fill',
  'film',
  'find',
  'fine',
  'fink',
  'fins',
  'fire',
  'firm',
  'firs',
  'fish',
  'fist',
  'fits',
  'five',
  'fizz',
  'flab',
  'flag',
  'flak',
  'flan',
  'flap',
  'flat',
  'flaw',
  'flax',
  'flay',
  'flea',
  'fled',
  'flee',
  'flew',
  'flex',
  'flip',
  'flit',
  'floe',
  'flog',
  'flop',
  'flow',
  'flub',
  'flue',
  'flux',
  'foal',
  'foam',
  'fobs',
  'foci',
  'foes',
  'fogs',
  'fogy',
  'foil',
  'fold',
  'folk',
  'fond',
  'font',
  'food',
  'fool',
  'foot',
  'fops',
  'fora',
  'ford',
  'fore',
  'fork',
  'form',
  'fort',
  'foul',
  'four',
  'fowl',
  'foxy',
  'frag',
  'frat',
  'fray',
  'free',
  'fret',
  'frog',
  'from',
  'fête',
  'ftps',
  'fuck',
  'fuel',
  'full',
  'fume',
  'fums',
  'fund',
  'funk',
  'furl',
  'furs',
  'fury',
  'fuse',
  'fuss',
  'futz',
  'fuze',
  'fuzz',
  'gabs',
  'gads',
  'gaff',
  'gage',
  'gags',
  'gain',
  'gait',
  'gala',
  'gale',
  'gall',
  'gals',
  'game',
  'gamy',
  'gang',
  'gape',
  'gaps',
  'garb',
  'gash',
  'gasp',
  'gate',
  'gave',
  'gawk',
  'gays',
  'gaze',
  'gear',
  'geed',
  'geek',
  'gees',
  'geez',
  'geld',
  'gels',
  'gelt',
  'gems',
  'gene',
  'gens',
  'gent',
  'germ',
  'gets',
  'gibe',
  'gift',
  'gigs',
  'gild',
  'gill',
  'gilt',
  'gins',
  'gird',
  'girl',
  'girt',
  'gist',
  'give',
  'glad',
  'glee',
  'glen',
  'glib',
  'glob',
  'glop',
  'glow',
  'glue',
  'glum',
  'glut',
  'gnat',
  'gnaw',
  'gnus',
  'goad',
  'goal',
  'goat',
  'gobs',
  'gods',
  'goes',
  'gold',
  'golf',
  'gone',
  'gong',
  'gonk',
  'good',
  'goof',
  'gook',
  'goon',
  'goop',
  'gore',
  'gorp',
  'gory',
  'gosh',
  'gout',
  'govt',
  'gown',
  'grab',
  'grad',
  'gram',
  'gray',
  'grep',
  'grew',
  'grey',
  'grid',
  'grim',
  'grin',
  'grip',
  'grit',
  'grog',
  'grok',
  'grow',
  'grub',
  'grue',
  'guff',
  'gulf',
  'gull',
  'gulp',
  'gums',
  'gunk',
  'guns',
  'guru',
  'gush',
  'gust',
  'guts',
  'guys',
  'gybe',
  'gyms',
  'gyps',
  'gyro',
  'hack',
  'haft',
  'hags',
  'hail',
  'hair',
  'hake',
  'hale',
  'half',
  'hall',
  'halo',
  'halt',
  'hams',
  'hand',
  'hang',
  'hank',
  'hard',
  'hare',
  'hark',
  'harm',
  'harp',
  'hart',
  'hash',
  'hasp',
  'hate',
  'hath',
  'hats',
  'haul',
  'have',
  'hawk',
  'haws',
  'hays',
  'haze',
  'hazy',
  'head',
  'heal',
  'heap',
  'hear',
  'heat',
  'heck',
  'heed',
  'heel',
  'heft',
  'heir',
  'held',
  'hell',
  'helm',
  'help',
  'hemp',
  'hems',
  'hens',
  'herb',
  'herd',
  'here',
  'hero',
  'hers',
  'hewn',
  'hews',
  'hick',
  'hide',
  'hied',
  'hies',
  'high',
  'hike',
  'hill',
  'hilt',
  'hims',
  'hind',
  'hing',
  'hint',
  'hips',
  'hire',
  'hiss',
  'hits',
  'hive',
  'hoax',
  'hobo',
  'hobs',
  'hock',
  'hods',
  'hoed',
  'hoes',
  'hogs',
  'hold',
  'hole',
  'holy',
  'home',
  'homy',
  'hone',
  'honk',
  'hood',
  'hoof',
  'hook',
  'hoop',
  'hoot',
  'hope',
  'hops',
  'horn',
  'hose',
  'host',
  'hour',
  'hove',
  'howl',
  'hows',
  'hubs',
  'hued',
  'hues',
  'huff',
  'huge',
  'hugs',
  'hula',
  'hulk',
  'hull',
  'hump',
  'hums',
  'hung',
  'hunk',
  'hunt',
  'hurl',
  'hurt',
  'hush',
  'husk',
  'huts',
  'hymn',
  'hype',
  'hypo',
  'iamb',
  'ibex',
  'ibid',
  'ibis',
  'iced',
  'ices',
  'icky',
  'icon',
  'idea',
  'ides',
  'idle',
  'idly',
  'idol',
  'idyl',
  'iffy',
  'ikon',
  'ilks',
  'ills',
  'imam',
  'imps',
  'inch',
  'incs',
  'info',
  'inks',
  'inky',
  'inns',
  'into',
  'ions',
  'iota',
  'iris',
  'irks',
  'iron',
  'isle',
  'isms',
  'itch',
  'item',
  'jabs',
  'jack',
  'jade',
  'jags',
  'jail',
  'jamb',
  'jams',
  'jape',
  'jars',
  'jaws',
  'jays',
  'jazz',
  'jeep',
  'jeer',
  'jeez',
  'jell',
  'jerk',
  'jest',
  'jets',
  'jibe',
  'jibs',
  'jigs',
  'jilt',
  'jinn',
  'jinx',
  'jive',
  'jobs',
  'jock',
  'jogs',
  'john',
  'join',
  'joke',
  'jolt',
  'josh',
  'jots',
  'jowl',
  'joys',
  'judo',
  'jugs',
  'jump',
  'junk',
  'jury',
  'just',
  'jute',
  'juts',
  'kale',
  'keel',
  'keen',
  'keep',
  'kegs',
  'kelp',
  'kens',
  'kept',
  'keto',
  'keys',
  'khan',
  'kick',
  'kids',
  'kill',
  'kiln',
  'kilo',
  'kilt',
  'kind',
  'king',
  'kink',
  'kiss',
  'kite',
  'kith',
  'kits',
  'kiwi',
  'knee',
  'knew',
  'knit',
  'knob',
  'knot',
  'know',
  'koan',
  'kook',
  'labs',
  'lace',
  'lack',
  'lacy',
  'lade',
  'lads',
  'lady',
  'lags',
  'laid',
  'lain',
  'lair',
  'lake',
  'lama',
  'lamb',
  'lame',
  'lamp',
  'lams',
  'élan',
  'land',
  'lane',
  'lank',
  'laps',
  'lard',
  'lark',
  'lase',
  'lash',
  'lass',
  'last',
  'late',
  'lath',
  'lats',
  'laud',
  'lava',
  'lawn',
  'laws',
  'lays',
  'laze',
  'lazy',
  'lead',
  'leaf',
  'leak',
  'lean',
  'leap',
  'leas',
  'leek',
  'leer',
  'lees',
  'left',
  'legs',
  'leis',
  'lend',
  'lens',
  'lent',
  'lept',
  'less',
  'lest',
  'lets',
  'levy',
  'lewd',
  'liar',
  'lice',
  'lick',
  'lids',
  'lied',
  'lief',
  'lien',
  'lies',
  'lieu',
  'life',
  'lift',
  'like',
  'lilt',
  'lily',
  'limb',
  'lime',
  'limn',
  'limo',
  'limp',
  'limy',
  'line',
  'link',
  'lint',
  'lion',
  'lips',
  'lira',
  'lire',
  'lisp',
  'list',
  'lite',
  'live',
  'load',
  'loaf',
  'loam',
  'loan',
  'lobe',
  'lobs',
  'loci',
  'lock',
  'loco',
  'lode',
  'loft',
  'loge',
  'logo',
  'logs',
  'loin',
  'loll',
  'lone',
  'long',
  'look',
  'loom',
  'loon',
  'loop',
  'loot',
  'lope',
  'lops',
  'lord',
  'lore',
  'lorn',
  'lose',
  'loss',
  'lost',
  'loth',
  'lots',
  'loud',
  'lout',
  'love',
  'lows',
  'luau',
  'lube',
  'luck',
  'lugs',
  'lull',
  'lump',
  'lung',
  'lure',
  'lurk',
  'lush',
  'lust',
  'lute',
  'lvii',
  'lxii',
  'lxiv',
  'lxix',
  'lxvi',
  'lynx',
  'lyre',
  'mace',
  'made',
  'mads',
  'maid',
  'mail',
  'maim',
  'main',
  'make',
  'male',
  'mall',
  'malt',
  'mama',
  'mane',
  'mans',
  'many',
  'maps',
  'mare',
  'mark',
  'mars',
  'mart',
  'masc',
  'mash',
  'mask',
  'mass',
  'mast',
  'mate',
  'math',
  'mats',
  'matt',
  'maul',
  'maws',
  'mayo',
  'maze',
  'mead',
  'meal',
  'mean',
  'meat',
  'meek',
  'meet',
  'megs',
  'meld',
  'melt',
  'meme',
  'memo',
  'mend',
  'menu',
  'meow',
  'mere',
  'mesa',
  'mesh',
  'mess',
  'meta',
  'mete',
  'mewl',
  'mews',
  'mica',
  'mice',
  'mien',
  'miff',
  'mike',
  'mild',
  'mile',
  'milf',
  'milk',
  'mill',
  'mils',
  'mime',
  'mind',
  'mine',
  'mini',
  'mink',
  'mint',
  'minx',
  'mire',
  'misc',
  'miss',
  'mist',
  'mite',
  'mitt',
  'mkay',
  'moan',
  'moat',
  'mobs',
  'mock',
  'mode',
  'mods',
  'mold',
  'mole',
  'moll',
  'molt',
  'moms',
  'monk',
  'mono',
  'mood',
  'moon',
  'moor',
  'moos',
  'moot',
  'mope',
  'mops',
  'more',
  'morn',
  'moss',
  'most',
  'mote',
  'moth',
  'move',
  'mown',
  'mows',
  'much',
  'muck',
  'muff',
  'mugs',
  'mule',
  'mull',
  'mung',
  'murk',
  'muse',
  'mush',
  'musk',
  'muss',
  'must',
  'mute',
  'mutt',
  'myna',
  'myth',
  'nabs',
  'nags',
  'nail',
  'name',
  'nape',
  'naps',
  'narc',
  'nark',
  'nary',
  'natl',
  'nave',
  'navy',
  'nays',
  'near',
  'neat',
  'neck',
  'need',
  'neon',
  'nerd',
  'nest',
  'nets',
  'news',
  'newt',
  'next',
  'nibs',
  'nice',
  'nick',
  'nigh',
  'nine',
  'nips',
  'nite',
  'nits',
  'node',
  'nods',
  'noel',
  'noes',
  'none',
  'nook',
  'noon',
  'nope',
  'norm',
  'nose',
  'nosh',
  'nosy',
  'note',
  'noun',
  'nous',
  'nova',
  'nubs',
  'nude',
  'nuke',
  'null',
  'numb',
  'nuns',
  'nuts',
  'oafs',
  'oaks',
  'oars',
  'oath',
  'oats',
  'obey',
  'obit',
  'oboe',
  'odds',
  'odes',
  'odor',
  'offs',
  'ogle',
  'ogre',
  'ohms',
  'oils',
  'oily',
  'oink',
  'okay',
  'okra',
  'oleo',
  'omen',
  'omit',
  'once',
  'ones',
  'only',
  'onto',
  'onus',
  'onyx',
  'oops',
  'ooze',
  'opal',
  'open',
  'opts',
  'opus',
  'oral',
  'orbs',
  'orcs',
  'ores',
  'orgy',
  'ouch',
  'ours',
  'oust',
  'outs',
  'oval',
  'oven',
  'over',
  'ovum',
  'owed',
  'owes',
  'owls',
  'owns',
  'oxen',
  'pace',
  'pack',
  'pact',
  'pads',
  'page',
  'paid',
  'pail',
  'pain',
  'pair',
  'pale',
  'pall',
  'palm',
  'pals',
  'pane',
  'pang',
  'pans',
  'pant',
  'papa',
  'paps',
  'pare',
  'park',
  'pars',
  'part',
  'pass',
  'past',
  'pate',
  'path',
  'pats',
  'pave',
  'pawl',
  'pawn',
  'paws',
  'pays',
  'épée',
  'peak',
  'peal',
  'pear',
  'peas',
  'peat',
  'peck',
  'pecs',
  'peed',
  'peek',
  'peel',
  'peep',
  'peer',
  'pees',
  'pegs',
  'pelt',
  'pend',
  'pens',
  'pent',
  'peon',
  'peps',
  'perk',
  'perm',
  'pert',
  'peso',
  'pest',
  'pets',
  'pews',
  'pica',
  'pick',
  'pied',
  'pier',
  'pies',
  'pigs',
  'pike',
  'pile',
  'pill',
  'pimp',
  'pine',
  'ping',
  'pink',
  'pins',
  'pint',
  'pipe',
  'pips',
  'piss',
  'pita',
  'pith',
  'pits',
  'pity',
  'pixy',
  'plan',
  'play',
  'plea',
  'pled',
  'plod',
  'plop',
  'plot',
  'plow',
  'ploy',
  'plug',
  'plum',
  'plus',
  'pock',
  'pods',
  'poem',
  'poet',
  'poke',
  'poky',
  'pole',
  'poll',
  'polo',
  'pols',
  'pomp',
  'pond',
  'pone',
  'pony',
  'pooh',
  'pool',
  'poop',
  'poor',
  'pope',
  'pops',
  'pore',
  'pork',
  'porn',
  'port',
  'pose',
  'posh',
  'poss',
  'post',
  'posy',
  'pots',
  'pour',
  'pout',
  'pram',
  'pray',
  'prep',
  'pres',
  'prey',
  'prig',
  'prim',
  'prod',
  'prof',
  'prom',
  'pron',
  'prop',
  'pros',
  'prow',
  'psst',
  'pubs',
  'puck',
  'puff',
  'pugs',
  'puke',
  'pull',
  'pulp',
  'puma',
  'pump',
  'punk',
  'puns',
  'punt',
  'puny',
  'pupa',
  'pups',
  'pure',
  'purl',
  'purr',
  'push',
  'puss',
  'puts',
  'putt',
  'pwns',
  'pyre',
  'quad',
  'quay',
  'ques',
  'quid',
  'quip',
  'quit',
  'quiz',
  'race',
  'rack',
  'racy',
  'raft',
  'raga',
  'rage',
  'rags',
  'raid',
  'rail',
  'rain',
  'raja',
  'rake',
  'ramp',
  'rams',
  'rang',
  'rank',
  'rant',
  'rape',
  'raps',
  'rapt',
  'rare',
  'rash',
  'rasp',
  'rate',
  'rats',
  'rave',
  'rays',
  'raze',
  'razz',
  'read',
  'real',
  'ream',
  'reap',
  'rear',
  'recd',
  'redo',
  'reds',
  'reed',
  'reef',
  'reek',
  'reel',
  'refs',
  'rehi',
  'rein',
  'reis',
  'rely',
  'rend',
  'rent',
  'reps',
  'resp',
  'rest',
  'revs',
  'rhea',
  'ribs',
  'rice',
  'rich',
  'rick',
  'ride',
  'rids',
  'rife',
  'riff',
  'rift',
  'rigs',
  'rile',
  'rill',
  'rime',
  'rims',
  'rind',
  'ring',
  'rink',
  'riot',
  'ripe',
  'rips',
  'rise',
  'risk',
  'rite',
  'road',
  'roam',
  'roan',
  'roar',
  'robe',
  'robs',
  'rock',
  'rode',
  'rods',
  'roes',
  'roil',
  'role',
  'roll',
  'romp',
  'rood',
  'roof',
  'rook',
  'room',
  'root',
  'rope',
  'rose',
  'rosy',
  'rote',
  'rots',
  'roué',
  'rout',
  'rove',
  'rows',
  'rube',
  'rubs',
  'ruby',
  'rude',
  'rued',
  'rues',
  'ruff',
  'rugs',
  'ruin',
  'rule',
  'rump',
  'rums',
  'rune',
  'rung',
  'runs',
  'runt',
  'ruse',
  'rush',
  'rusk',
  'rust',
  'ruts',
  'sack',
  'sacs',
  'safe',
  'saga',
  'sage',
  'sago',
  'sags',
  'said',
  'sail',
  'sake',
  'saki',
  'sale',
  'salt',
  'same',
  'sand',
  'sane',
  'sang',
  'sank',
  'sans',
  'saps',
  'sari',
  'sash',
  'sass',
  'sate',
  'save',
  'sawn',
  'saws',
  'says',
  'scab',
  'scad',
  'scag',
  'scam',
  'scan',
  'scar',
  'scat',
  'scow',
  'scud',
  'scum',
  'seal',
  'seam',
  'sear',
  'seas',
  'seat',
  'secs',
  'sect',
  'secy',
  'seed',
  'seek',
  'seem',
  'seen',
  'seep',
  'seer',
  'sees',
  'self',
  'sell',
  'semi',
  'send',
  'sent',
  'sera',
  'sere',
  'serf',
  'sets',
  'sewn',
  'sews',
  'sexy',
  'shad',
  'shag',
  'shah',
  'sham',
  'shat',
  'shed',
  'shes',
  'shim',
  'shin',
  'ship',
  'shit',
  'shod',
  'shoe',
  'shoo',
  'shop',
  'shot',
  'show',
  'shun',
  'shut',
  'sick',
  'sics',
  'side',
  'sift',
  'sigh',
  'sign',
  'silk',
  'sill',
  'silo',
  'silt',
  'sims',
  'sine',
  'sing',
  'sink',
  'sins',
  'sips',
  'sire',
  'sirs',
  'site',
  'sits',
  'situ',
  'size',
  'skew',
  'skid',
  'skim',
  'skin',
  'skip',
  'skis',
  'skit',
  'slab',
  'slag',
  'slam',
  'slap',
  'slat',
  'slaw',
  'slay',
  'sled',
  'slew',
  'slid',
  'slim',
  'slip',
  'slit',
  'slob',
  'sloe',
  'slog',
  'slop',
  'slot',
  'slow',
  'slue',
  'slug',
  'slum',
  'slur',
  'slut',
  'smit',
  'smog',
  'smug',
  'smut',
  'snag',
  'snap',
  'snip',
  'snit',
  'snob',
  'snot',
  'snow',
  'snub',
  'snug',
  'soak',
  'soap',
  'soar',
  'sobs',
  'sock',
  'soda',
  'sods',
  'sofa',
  'soft',
  'soil',
  'sold',
  'sole',
  'soli',
  'solo',
  'sols',
  'some',
  'song',
  'sons',
  'soon',
  'soot',
  'sops',
  'sore',
  'sort',
  'sots',
  'soul',
  'soup',
  'sour',
  'sown',
  'sows',
  'soya',
  'spam',
  'span',
  'spar',
  'spas',
  'spat',
  'spay',
  'spec',
  'sped',
  'spew',
  'spin',
  'spit',
  'spot',
  'spry',
  'spud',
  'spun',
  'spur',
  'stab',
  'stag',
  'star',
  'stat',
  'stay',
  'stem',
  'step',
  'stew',
  'stir',
  'stop',
  'stow',
  'stub',
  'stud',
  'stun',
  'stye',
  'subj',
  'subs',
  'such',
  'suck',
  'suds',
  'sued',
  'sues',
  'suet',
  'suit',
  'sulk',
  'sumo',
  'sump',
  'sums',
  'sung',
  'sunk',
  'suns',
  'sups',
  'sure',
  'surf',
  'swab',
  'swag',
  'swam',
  'swan',
  'swap',
  'swat',
  'sway',
  'swig',
  'swim',
  'swop',
  'swum',
  'sync',
  'tabs',
  'tabu',
  'tack',
  'taco',
  'tact',
  'tads',
  'tags',
  'tail',
  'take',
  'talc',
  'tale',
  'talk',
  'tall',
  'tame',
  'tamp',
  'tams',
  'tang',
  'tank',
  'tans',
  'tape',
  'taps',
  'tare',
  'taro',
  'tarp',
  'tars',
  'tart',
  'task',
  'tats',
  'taut',
  'taxi',
  'tbsp',
  'teak',
  'teal',
  'team',
  'tear',
  'teas',
  'teat',
  'tech',
  'teed',
  'teem',
  'teen',
  'tees',
  'tell',
  'temp',
  'tend',
  'tens',
  'tent',
  'term',
  'tern',
  'test',
  'text',
  'than',
  'that',
  'thaw',
  'thee',
  'them',
  'then',
  'they',
  'thin',
  'this',
  'thou',
  'thru',
  'thud',
  'thug',
  'thus',
  'tick',
  'tics',
  'tide',
  'tidy',
  'tied',
  'tier',
  'ties',
  'tiff',
  'tike',
  'tile',
  'till',
  'tilt',
  'time',
  'tine',
  'ting',
  'tins',
  'tint',
  'tiny',
  'tipi',
  'tips',
  'tire',
  'tiro',
  'tits',
  'toad',
  'toed',
  'toes',
  'tofu',
  'toga',
  'togs',
  'toil',
  'toke',
  'told',
  'toll',
  'tomb',
  'tome',
  'toms',
  'tone',
  'tong',
  'tons',
  'tony',
  'took',
  'tool',
  'toot',
  'tops',
  'tore',
  'torn',
  'tors',
  'tort',
  'toss',
  'tost',
  'tote',
  'tots',
  'tour',
  'tout',
  'town',
  'tows',
  'toys',
  'tram',
  'trap',
  'tray',
  'tree',
  'trek',
  'trig',
  'trim',
  'trio',
  'trip',
  'trod',
  'tron',
  'trot',
  'troy',
  'true',
  'tsar',
  'ttys',
  'tuba',
  'tube',
  'tubs',
  'tuck',
  'tuft',
  'tugs',
  'tuna',
  'tune',
  'tuns',
  'turd',
  'turf',
  'turn',
  'tush',
  'tusk',
  'tutu',
  'twee',
  'twig',
  'twin',
  'twit',
  'twos',
  'tyke',
  'type',
  'typo',
  'tyro',
  'tzar',
  'ugly',
  'ulna',
  'umps',
  'undo',
  'unit',
  'unto',
  'upon',
  'urea',
  'urge',
  'uric',
  'urns',
  'used',
  'user',
  'uses',
  'vain',
  'vale',
  'vamp',
  'vane',
  'vans',
  'vape',
  'vars',
  'vary',
  'vase',
  'vast',
  'vats',
  'veal',
  'veep',
  'veer',
  'veil',
  'vein',
  'veld',
  'vend',
  'vent',
  'verb',
  'very',
  'vest',
  'veto',
  'vets',
  'vial',
  'vibe',
  'vice',
  'vied',
  'vies',
  'view',
  'viii',
  'vile',
  'vine',
  'viol',
  'visa',
  'vise',
  'viva',
  'void',
  'vole',
  'vols',
  'volt',
  'vote',
  'vows',
  'wack',
  'wade',
  'wadi',
  'wads',
  'waft',
  'wage',
  'wags',
  'waif',
  'wail',
  'wait',
  'wake',
  'wale',
  'walk',
  'wall',
  'wand',
  'wane',
  'wank',
  'want',
  'ward',
  'ware',
  'warm',
  'warn',
  'warp',
  'wars',
  'wart',
  'wary',
  'wash',
  'wasp',
  'watt',
  'wave',
  'wavy',
  'waxy',
  'ways',
  'weak',
  'weal',
  'wean',
  'wear',
  'webs',
  'weds',
  'weed',
  'week',
  'weep',
  'weer',
  'wees',
  'weft',
  'weir',
  'weld',
  'well',
  'welt',
  'wend',
  'wens',
  'went',
  'wept',
  'were',
  'west',
  'wets',
  'wham',
  'what',
  'when',
  'whet',
  'whew',
  'whey',
  'whim',
  'whip',
  'whir',
  'whit',
  'whiz',
  'whoa',
  'whom',
  'whys',
  'wick',
  'wide',
  'wife',
  'wigs',
  'wiki',
  'wild',
  'wile',
  'will',
  'wilt',
  'wily',
  'wimp',
  'wind',
  'wine',
  'wing',
  'wink',
  'wino',
  'wins',
  'wipe',
  'wire',
  'wiry',
  'wise',
  'wish',
  'wisp',
  'wist',
  'with',
  'wits',
  'wive',
  'wkly',
  'woes',
  'woke',
  'woks',
  'wolf',
  'womb',
  'wont',
  'wood',
  'woof',
  'wool',
  'woos',
  'word',
  'wore',
  'work',
  'worm',
  'worn',
  'wove',
  'wows',
  'wrap',
  'wren',
  'writ',
  'wuss',
  'xcii',
  'xciv',
  'xcix',
  'xcvi',
  'xiii',
  'xref',
  'xvii',
  'xxii',
  'xxiv',
  'xxix',
  'xxvi',
  'xxxi',
  'xxxv',
  'yack',
  'yaks',
  'yams',
  'yank',
  'yaps',
  'yard',
  'yarn',
  'yawl',
  'yawn',
  'yaws',
  'yeah',
  'year',
  'yeas',
  'yell',
  'yelp',
  'yens',
  'yeps',
  'yest',
  'yeti',
  'yews',
  'yips',
  'yock',
  'yoga',
  'yogi',
  'yoke',
  'yolk',
  'yore',
  'your',
  'yous',
  'yowl',
  'yuck',
  'yuks',
  'yule',
  'yups',
  'zany',
  'zaps',
  'zeal',
  'zebu',
  'zeds',
  'zens',
  'zero',
  'zest',
  'zeta',
  'zinc',
  'zing',
  'zips',
  'zits',
  'zone',
  'zoom',
  'zoos',
]
